import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './products.css';

const Products = () => {
    const containerRefs = useRef([]);
    containerRefs.current = [];

    const addToRefs = (el) => {
        if (el && !containerRefs.current.includes(el)) {
            containerRefs.current.push(el);
        }
    };

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                }
            });
        }, { threshold: 0.1 });

        containerRefs.current.forEach(ref => {
            if (ref) observer.observe(ref);
        });

        return () => {
            containerRefs.current.forEach(ref => {
                if (ref) observer.unobserve(ref);
            });
        };
    }, []);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('http://localhost:3001/send-email', {
                name,
                email,
                message,
            });
            if (response.status === 200) {
                alert('Email sent successfully!');
                setName('');
                setEmail('');
                setMessage('');
            }
        } catch (error) {
            alert('Failed to send email. Please try again later.');
        }
    };

    return (
        <>
            <div>
                <img src='https://res.cloudinary.com/drevfgyks/image/upload/v1725173288/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/1_i5nhhd.jpg'
                alt='product-banner' className='products-banner-img'/>
            </div>
            <div className="products-container">
                <h1>Medicinal and aromatics and exotics plantations.</h1>
                <div ref={addToRefs} className="product" data-animation="slide-left">
                    <div className='product-content margin-two'>
                    <h2>Agarwood (Aquilaria spp.) / Wood Bearing Crops</h2>
                    <p className='product-des'>Wood bearing crops include trees and plants cultivated primarily for their timber, which is used in construction, furniture, and paper production. Common examples include teak, mahogany, and pine, which are integral to forestry and industrial applications.</p>
                    <p className='product-des'>Agarwood is a resinous heartwood formed in trees of the Aquilaria genus, most notably when infected by a specific type of mold. This resin is incredibly rare and is used to create a rich, smoky aroma in perfumes and incense, especially in Middle Eastern and Asian markets.</p>
                    <p className='product-des'>Uses: Perfume and incense production.- Traditional medicine in many Asian cultures.- Ornamental wood carving and luxury goods.
                        <br/>Sustainability: Due to high demand, wild Agarwood trees are threatened by illegal logging. Sustainable plantations now focus on ethical harvesting and resin cultivation to meet global demand without depleting natural forests.</p>
                    </div>
                    <img src="https://res.cloudinary.com/drevfgyks/image/upload/v1729503605/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/agree_us4ltq.jpg"
                    className="product-image margin-img-two" alt="Landscaping" />
                </div>
                <div className='sub-main-container'>
                    <div className="sub-content">
                        <h3>Health Benefits of Agarwood</h3>
                        <ul className='product-des'>
                            <li><strong>Anti-inflammatory and Analgesic Effects:</strong> Helps alleviate pain, reduce inflammation, and relieve joint and muscle soreness.</li>
                            <li><strong>Antioxidant Properties:</strong> Contains antioxidants that protect against oxidative stress and support cellular health.</li>
                            <li><strong>Digestive Health:</strong> Aids digestion and alleviates gastrointestinal issues like bloating, indigestion, and gas.</li>
                            <li><strong>Stress Relief and Mental Clarity:</strong> The soothing aroma reduces anxiety and promotes relaxation and mental clarity.</li>
                            <li><strong>Antimicrobial and Antifungal Properties:</strong> Fights pathogens, supports skin health, and aids in wound healing.</li>
                            <li><strong>Respiratory Health:</strong> Used in TCM for respiratory issues, helping with asthma and congestion.</li>
                            <li><strong>Cardiovascular Health:</strong> Promotes better circulation and reduces stress, potentially benefiting heart health.</li>
                            <li><strong>Improved Sleep Quality:</strong> The calming effects aid in better sleep and are used as a natural sleep aid.</li>
                            <li><strong>Hormonal Balance and Libido:</strong> Traditionally considered an aphrodisiac and supports hormonal health.</li>
                            <li><strong>Skin Health and Beauty:</strong> Has moisturizing and anti-aging effects for a smoother, youthful appearance.</li>
                        </ul>

                        <h3>How to Use Agarwood</h3>
                        <ul className='product-des'>
                            <strong>Agarwood Tea:</strong> Supports digestive health, stress relief, and overall wellness.<br />
                            <strong>Essential Oil:</strong> Used in aromatherapy for relaxation, pain relief, and skin care.<br />
                            <strong>Incense and Fragrance:</strong> Inhaling helps reduce anxiety and improve focus.<br />
                            <strong>Supplements:</strong> Agarwood extracts are available in capsule or tincture forms (consult a healthcare provider before use).
                        </ul>

                        <h3>Safety and Precautions</h3>
                        <ul className='product-des'>
                            Generally safe when used moderately. Pregnant or breastfeeding women should consult a healthcare provider before using. Dilute agarwood oil with a carrier oil before applying to the skin.
                        </ul>
                    </div>
                    <div className="product-images">
                        <img src="https://res.cloudinary.com/drevfgyks/image/upload/v1729953366/WhatsApp_Image_2024-10-26_at_18.09.40_543c7d4c_toc3te.jpg" alt="Agarwood Image 1" className="product-image-sub" />
                        <img src="https://res.cloudinary.com/drevfgyks/image/upload/v1729953365/WhatsApp_Image_2024-10-26_at_18.08.19_0567bc19_qqip2m.jpg" alt="Agarwood Image 2" className="product-image-sub" />
                        <img src="https://res.cloudinary.com/drevfgyks/image/upload/v1729955112/WhatsApp_Image_2024_n2c8tc.jpg" alt="Agarwood Image 3" className="product-image-sub" />
                        <img src="https://res.cloudinary.com/drevfgyks/image/upload/v1729953366/WhatsApp_Image_2024-10-26_at_18.11.22_6cad0ce2_fndfwr.jpg" alt="Agarwood Image 4" className="product-image-sub" />
                    </div>
                </div>
                <div ref={addToRefs} className="product" data-animation="slide-left">
                    <div className='product-content margin-two'>
                    <h2>Mahogany (Swietenia spp.)</h2>
                    <p className='product-des'>Mahogany is one of the most popular and durable hardwoods used globally, known for its deep reddish-brown color and fine grain. It is commonly grown in Central and South America as well as parts of Africa and Southeast Asia.</p>
                    <p className='product-des'>Uses: Perfume and incense production.- Traditional medicine in many Asian cultures.- Ornamental wood carving and luxury goods.
                        <br/>Sustainability: Due to high demand, wild Agarwood trees are threatened by illegal logging. Sustainable plantations now focus on ethical harvesting and resin cultivation to meet global demand without depleting natural forests.</p>
                    </div>
                    <img src="https://res.cloudinary.com/drevfgyks/image/upload/v1729503605/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/moho_urpmzo.jpg"
                    className="product-image margin-img-two" alt="Landscaping" />
                </div>
                <div ref={addToRefs} className="product" data-animation="slide-right">

                <div className='product-content margin-two'>
                    <h2>Rose wood</h2>
                    <p className='product-des'>Rosewood, scientifically known as Dalbergia spp., is a prized hardwood known for its striking color, 
                        fine grain, and smooth texture. Valued for its durability and unique aroma, it is commonly used in the production of luxury 
                        furniture, musical instruments, and decorative veneers. Its high demand, especially in international markets, makes rosewood 
                        cultivation a potentially lucrative agricultural venture.</p>
                    </div>
                    <img src="https://res.cloudinary.com/drevfgyks/image/upload/v1729941629/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/WhatsApp_Image_2024-10-23_at_13.05.31_f383163e_jkyjia.jpg"
                    className="product-image margin-img-two" alt="Wild Species Crops" />

                    
                </div> 
                <div ref={addToRefs} className="product" data-animation="slide-left">
                <img src="https://res.cloudinary.com/drevfgyks/image/upload/v1729941629/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/WhatsApp_Image_2024-10-23_at_13.08.20_6ec09beb_txg12u.jpg" 
                    className="product-image margin-img-one" alt="Avenue crops" />
                    <div className='product-content margin-one'>
                    <h2>White Sandalwood (Santalum album)</h2>
                    <p className='product-des'>White Sandalwood, known for its fragrant heartwood, is one of the most precious woods in the world. It is predominantly cultivated in India, Australia, and Southeast Asia. The aromatic oil extracted from White Sandalwood is prized in perfumery, aromatherapy, and religious ceremonies.</p>
                    <p className='product-des'>Uses: Essential oil extraction for perfumes, incense, and skincare. Carving material for religious artifacts and ceremonial items.- Aromatherapy applications due to its calming properties.
                        <br/>Sustainability: White Sandalwood trees take decades to mature, making long-term investment in sustainable agroforestry essential. Plantation methods have been developed to ensure a balance between harvesting and replenishment.</p>
                    </div>
                </div> 
                <div ref={addToRefs} className="product" data-animation="slide-right">
                    <div className='product-content margin-one'>
                        <h2>Red Sandalwood (Pterocarpus santalinus)</h2>
                        <p className='product-des'>Red Sandalwood, also known as Rakta Chandana, is a highly valued hardwood primarily grown in southern India. It is renowned for its deep red hue and rich grain patterns, making it sought-after in the furniture and carving industries.</p>
                        <p className='product-des'>Uses: Widely used in high-end furniture and handicrafts.- Employed in natural dye production for textiles and cosmetics.- Traditionally used in Ayurvedic medicine for its anti-inflammatory and healing properties.
                        <br/>Sustainability: Due to its over-exploitation, Red Sandalwood is classified as an endangered species, making sustainable cultivation practices vital for its conservation.</p>
                    </div>
                    <img src="https://res.cloudinary.com/drevfgyks/image/upload/v1729941629/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/WhatsApp_Image_2024-10-23_at_13.07.19_f86e2a5b_sqxned.jpg" 
                    className="product-image margin-img-one" alt="Avenue crops" />
                </div>             
            </div>
            <div className="products-container">
                <div ref={addToRefs} className="product" data-animation="slide-right">
                    <img src="https://res.cloudinary.com/drevfgyks/image/upload/v1725105490/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/1_iho23y.png" 
                    className="product-image margin-img-one" alt="Field crops" />
                    <div className='product-content margin-one'>
                        <h2>Field Crops</h2>
                        <p className='product-des'>Field crops are essential for agriculture, providing staple food and raw materials for industries. They include crops like wheat, rice, corn, and barley, cultivated on a large scale for human consumption and livestock feed.</p>
                    </div>
                </div>
                <div ref={addToRefs} className="product" data-animation="slide-left">
                    <div className='product-content margin-two'>
                        <h2>Medicinal, aromatic Oil Crops</h2>
                        <p className='product-des'>Medicinal and aromatic oil crops are cultivated for their rich therapeutic, aromatic, and industrial value. These crops produce essential oils and bioactive compounds used in pharmaceuticals, cosmetics, and traditional medicine.</p>
                        <p>Include: Lemongrass (Cymbopogon citratus), Palmarosa (Cymbopogon martini), Citronella (Cymbopogon nardus), Kaempferia (Kaempferia galanga), Vetiver (Chrysopogon zizanioides), Davana (Artemisia pallens).</p>
                    </div>
                    <img src="https://res.cloudinary.com/drevfgyks/image/upload/v1725105495/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/2_pnju9o.png"
                    className="product-image margin-img-two" alt="Oil crops" />
                </div>
            </div>

            <div className="products-container">
                <div ref={addToRefs} className="product" data-animation="slide-right">
                    <img src="https://res.cloudinary.com/drevfgyks/image/upload/v1725105499/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/3_zqlliw.png" 
                    className="product-image margin-img-one" alt="Horticulture crops" />
                    <div className='product-content margin-one'>
                        <h2>Horticulture Crops</h2>
                        <p className='product-des'>Horticulture crops include fruits, vegetables, and ornamental plants that are cultivated for their edible and aesthetic value, supporting nutrition and garden beauty.</p>
                    </div>
                </div>
                <div ref={addToRefs} className="product" data-animation="slide-left">
                    <div className='product-content margin-two'>
                        <h2>Exotic Fruits Crops</h2>
                        <p className='product-des'> Exotic fruit crops are a rich source of essential vitamins, minerals, and nutrients, cherished for their unique flavors and health benefits. These fruits are often cultivated in specific climates and are gaining popularity for their nutritional and culinary value, Include: Avocado (Persea americana), Macadamia (Macadamia integrifolia).</p>
                    </div>
                    <img src="https://res.cloudinary.com/drevfgyks/image/upload/v1725105497/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/4_uvjxbb.png"
                    className="product-image margin-img-two" alt="Fruit crops" />
                </div>
            </div>

            <div className="products-container"> 
                <div ref={addToRefs} className="product" data-animation="slide-right">
                    <img src="https://res.cloudinary.com/drevfgyks/image/upload/v1725105498/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/5_a4mptc.png" 
                    className="product-image margin-img-one" alt="Flower crops" />
                    <div className='product-content margin-one'>
                        <h2>Flower Crops</h2>
                        <p className='product-des'>Flower crops are cultivated for their aesthetic and commercial value. They are essential in the ornamental industry, with flowers like roses, lilies, and marigolds being grown for decoration, perfumes, and as cultural symbols in ceremonies.</p>
                    </div>
                </div>
                <div ref={addToRefs} className="product" data-animation="slide-left">
                    {/* <div className='product-content margin-two'>
                        <h2>Vegetable Crops</h2>
                        <p className='product-des'>Vegetable crops are crucial for human nutrition, providing essential vitamins, minerals, and fiber. Common vegetable crops include tomatoes, lettuce, carrots, and spinach, which are grown globally for daily consumption.</p>
                    </div> */}
                    <div className='product-content margin-one'>
                        <h2>Medicinal Crops</h2>
                        <p className='product-des'>Medicinal crops are cultivated for their therapeutic properties, used in traditional and modern medicine. Plants like aloe vera, turmeric, and mint are grown for their healing qualities and are integral to healthcare and wellness industries.
                        Medicinal crops play a vital role in both nutrition and healthcare, Nowadays, medicinal plants are increasingly used as natural food supplements to prevent diseases and promote overall well-being in a holistic and natural way. Include:
                        Ashwagandha (Withania somnifera), Shatavari (Asparagus racemosus), Licorice (Glycyrrhiza glabra), Gudmar (Gymnema sylvestre). 
                        </p>
                    </div>
                        <img src="https://res.cloudinary.com/drevfgyks/image/upload/v1725105498/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/7_vndxzw.png"
                        className="product-image margin-img-two" alt="Vegetable crops" />
                </div>
            </div>

            <div className="products-container">
                <div ref={addToRefs} className="product" data-animation="slide-right">
                    {/* <img src="https://res.cloudinary.com/drevfgyks/image/upload/v1725105498/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/7_vndxzw.png" 
                    className="product-image margin-img-one" alt="Medicinal crops" /> */}
                    {/* <div className='product-content margin-one'>
                        <h2>Medicinal Crops</h2>
                        <p className='product-des'>Medicinal crops are cultivated for their therapeutic properties, used in traditional and modern medicine. Plants like aloe vera, turmeric, and mint are grown for their healing qualities and are integral to healthcare and wellness industries.
                        Medicinal crops play a vital role in both nutrition and healthcare. Nowadays, medicinal plants are increasingly used as natural food supplements to prevent diseases and promote overall well-being in a holistic and natural way. Include:
                        </p>
                    </div> */}
                </div>
                {/* <div ref={addToRefs} className="product" data-animation="slide-left">
                    <div className='product-content margin-two'>
                        <h2>Coffee & Tea Estates</h2>
                        <p className='product-des'>Coffee and tea estates are large plantations where coffee and tea plants are grown for global consumption. These crops are crucial to many economies and cultures, providing popular beverages enjoyed worldwide.</p>
                    </div>
                    <img src="https://res.cloudinary.com/drevfgyks/image/upload/v1725105500/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/6_q4lr7r.png"
                    className="product-image margin-img-two" alt="Coffee & Tea Estates" />
                </div> */}
            </div>

            <div className="products-container"> 
                {/* <div ref={addToRefs} className="product" data-animation="slide-right">
                    <img src="https://res.cloudinary.com/drevfgyks/image/upload/v1725105492/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/9_xrjkjy.png" 
                    className="product-image margin-img-one" alt="Plantation crops" />
                    <div className='product-content margin-one'>
                    <h2>Plantation Crops</h2>
                    <p className='product-des'>Plantation crops include commercially valuable crops grown on a large scale, such as rubber, coconut, and sugarcane. These crops are typically cultivated in tropical regions and play a significant role in global trade and economy.</p>
                    </div>
                </div> */}
                <div ref={addToRefs} className="product" data-animation="slide-left">
                    <div className='product-content margin-two'>
                    <h2>Exotic Crops</h2>
                    <p className='product-des'>Exotic crops are non-native plants cultivated for their unique flavors, textures, or medicinal properties. These crops, like quinoa, kiwi, and dragon fruit, are gaining popularity in global markets for their nutritional benefits and novelty.</p>
                    </div>
                    <img src="https://res.cloudinary.com/drevfgyks/image/upload/v1725105495/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/10_ld1usu.png"
                    className="product-image margin-img-two" alt="Exotic Crops" />
                </div>
            </div>
            <div className="products-container">
                <div ref={addToRefs} className="product" data-animation="slide-right">
                    <img src="https://res.cloudinary.com/drevfgyks/image/upload/v1725105490/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/13_hmmunw.png" 
                    className="product-image margin-img-one" alt="Spices crops" />
                    <div className='product-content margin-one'>
                    <h2>Spices Crops</h2>
                    <p className='product-des'>Spices crops are grown for their aromatic, pungent, and flavorful properties, used in cooking, medicine, and cosmetics. Crops like black pepper, cinnamon, and cloves are harvested for their ability to enhance the taste and fragrance of food.</p>
                    </div>
                </div>
                {/* <div ref={addToRefs} className="product" data-animation="slide-left">
                    <div className='product-content margin-two'>
                    <h2>Wild Species Crops</h2>
                    <p className='product-des'>Wild species crops are plants that have not been domesticated and are harvested from their natural habitats. These crops provide genetic diversity and are essential for breeding programs, conservation, and as a source of food and medicine for indigenous communities.</p>
                    </div>
                    <img src="https://res.cloudinary.com/drevfgyks/image/upload/v1725105495/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/11_yjplav.png"
                    className="product-image margin-img-two" alt="Wild Species Crops" />
                </div> */}
            </div>

            {/* <div className="products-container"> 
                <div ref={addToRefs} className="product" data-animation="slide-right">
                    <img src="https://res.cloudinary.com/drevfgyks/image/upload/v1725105494/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/14_folbts.png" 
                    className="product-image margin-img-one" alt="Wood Bearing crops" />
                    <div className='product-content margin-one'>
                    <h2>Wood Bearing Crops</h2>
                    <p className='product-des'>Wood bearing crops include trees and plants cultivated primarily for their timber, which is used in construction, furniture, and paper production. Common examples include teak, mahogany, and pine, which are integral to forestry and industrial applications.</p>
                    </div>
                </div>                
            </div> */}
            <p className="EFE">Emerging Farmers Empowerment</p>
        </>
    );
};

export default Products;
