import React from "react";
import { FaUserTie } from "react-icons/fa"; // Import developer icon
import "./reachdeveloper.css";

const ReachDeveloper = () => {
  return (
    <div className="ReachDeveloper-container">
      <h1 className="ReachDeveloper-heading">Reach Our Developer</h1>
      <div className="ReachDeveloper-card">
        <div className="ReachDeveloper-profile">
          <FaUserTie className="ReachDeveloper-icon" />
        </div>
        <h2 className="ReachDeveloper-name">Tech Nanisai</h2>
        <p className="ReachDeveloper-detail">
          <strong>Email:</strong> nanisaiofficial5@gmail.com
        </p>
        <p className="ReachDeveloper-detail">
          <strong>Contact:</strong> 9948946658
        </p>
        <p className="ReachDeveloper-detail">
          <strong>Website:</strong> www.nanisai.com <span>(Coming Soon...)</span>
        </p>
      </div>
    </div>
  );
};

export default ReachDeveloper;
