import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Home from './components/Home/index';
import Footer from './components/Footer/Footer';  // Import the Footer component
import AboutUs from './components/AboutUs/aboutus'; // Import the AboutUs component
import Products from './components/Products/products'; // Import the Products component
import AgriClinicalServices from './components/AgriClinicalServices/AgriClinicalServices'; // Import the AgriClinicalServices component
import AgriBusinessServices from './components/AgriBusinessServices/AgriBusinessServices'; // Import the AgriBusinessServices component
import Contacts from './components/Contacts/contact';
import Projects from './components/Projects/Projects';
import Services  from './components/Services/services';
import OurProcess from './components/OurProcess/ourprocess';
import Career from './components/Career/Career'; // Import the Career component
import Gallery from './components/Gallery//Gallery'; // Import the Gallery component
import SocialMedia from './components/SocialMedia/SocialMedia';
import BenefitsDetails from './components/BenefitsDetails/BenefitsDetails';
import AgriTraining from './components/AgriTraining/AgriTraining';
import ValueEdition from './components/ValueEdition/ValueEdition';
import ReachDeveloper from './components/ReachDeveloper/reachdeveloper';
import './App.css';

function App() {
  return (
    <div className="App">
      <Navbar />
      <SocialMedia/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/crops-products" element={<Products />} />
        <Route path="/arg-clinical" element={<AgriClinicalServices />} />
        <Route path="/arg-business" element={<AgriBusinessServices />} />
        <Route path="/contact" element={<Contacts />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/our-services" element={<Services />} />
        <Route path="/our-Process" element={<OurProcess />} />
        <Route path="/career" element={<Career />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/benefitsdetails" element={<BenefitsDetails />} />
        <Route path="/agriTraining" element={<AgriTraining />} />
        <Route path="/ValueEdition" element={<ValueEdition />} />
        <Route path="/Web-Developer-details" element={<ReachDeveloper />} />
      </Routes>
      <Footer />  {/* Add the Footer component here */}
    </div>
  );
}

export default App;
